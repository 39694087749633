import * as React from "react";
// @ts-ignore
import classes from "./Distributor.module.css";

const DistributorItem = (props: any) => {

  const { country, hops, handleModal, backgroundImage, hopBug } = props;

  const handleClick = () => {
    handleModal({ country, hops });
  };

  return (
    <div className={classes.centerSectionContainer}>
      <div
        onClick={handleClick}
        className={classes.countryContainer}
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <img src={hopBug} alt={country} className={classes.countryBug} />
      </div>
      <h2 className={classes.countryName}>{country}</h2>
    </div>
  );
};

export default DistributorItem;
