import Leaf_2_Beer_Animations_1 from "../../../../images/beer/Leaf_2_Beer_Animations_1.png"
import Hop_4_copy_Beer_Animations_1 from "../../../../images/beer/Hop_4_copy_Beer_Animations_1.png"
import Hop_3_Beer_Animations_1 from "../../../../images/beer/Hop_3_Beer_Animations_1.png"
import Hop1_Beer_Animations_1 from "../../../../images/beer/Hop1_Beer_Animations_1.png"
import Leaf_3_Beer_Animations_1 from "../../../../images/beer/Leaf_3_Beer_Animations_1.png"
import Hop_2_Beer_Animations_1 from "../../../../images/beer/Hop_2_Beer_Animations_1.png"
import Hop_5_copy_Beer_Animations_1 from "../../../../images/beer/Hop_5_copy_Beer_Animations_1.png"
import beer_Beer_Animations_1 from "../../../../images/beer/beer_Beer_Animations_1.png"
import Hop_4_Beer_Animations_1 from "../../../../images/beer/Hop_4_Beer_Animations_1.png"
import Leaf_5_Beer_Animations_1 from "../../../../images/beer/Leaf_5_Beer_Animations_1.png"
import Hop_5_Beer_Animations_1 from "../../../../images/beer/Hop_5_Beer_Animations_1.png"
import Leaf_4_Beer_Animations_1 from "../../../../images/beer/Leaf_4_Beer_Animations_1.png"
import Leaf_1_Beer_Animations_1 from "../../../../images/beer/Leaf_1_Beer_Animations_1.png"

export default {
  "v": "5.6.3",
  "fr": 24,
  "ip": 0,
  "op": 97,
  "w": 500,
  "h": 500,
  "nm": "Beer_Animations_1",
  "ddd": 0,
  "assets": [
    {
      "id": "image_0",
      "w": 500,
      "h": 500,
      "u": "",
      "p": Leaf_2_Beer_Animations_1,
      "e": 0
    },
    {
      "id": "image_1",
      "w": 500,
      "h": 500,
      "u": "",
      "p": Hop_4_copy_Beer_Animations_1,
      "e": 0
    },
    {
      "id": "image_2",
      "w": 500,
      "h": 500,
      "u": "",
      "p": Hop_3_Beer_Animations_1,
      "e": 0
    },
    {
      "id": "image_3",
      "w": 500,
      "h": 500,
      "u": "",
      "p": Hop1_Beer_Animations_1,
      "e": 0
    },
    {
      "id": "image_4",
      "w": 500,
      "h": 500,
      "u": "",
      "p": Leaf_3_Beer_Animations_1,
      "e": 0
    },
    {
      "id": "image_5",
      "w": 500,
      "h": 500,
      "u": "",
      "p": Hop_2_Beer_Animations_1,
      "e": 0
    },
    {
      "id": "image_6",
      "w": 500,
      "h": 500,
      "u": "",
      "p": Hop_5_copy_Beer_Animations_1,
      "e": 0
    },
    {
      "id": "image_7",
      "w": 500,
      "h": 500,
      "u": "",
      "p": beer_Beer_Animations_1,
      "e": 0
    },
    {
      "id": "image_8",
      "w": 500,
      "h": 500,
      "u": "",
      "p": Hop_4_Beer_Animations_1,
      "e": 0
    },
    {
      "id": "image_9",
      "w": 500,
      "h": 500,
      "u": "",
      "p": Leaf_5_Beer_Animations_1,
      "e": 0
    },
    {
      "id": "image_10",
      "w": 500,
      "h": 500,
      "u": "",
      "p": Hop_5_Beer_Animations_1,
      "e": 0
    },
    {
      "id": "image_11",
      "w": 500,
      "h": 500,
      "u": "",
      "p": Leaf_4_Beer_Animations_1,
      "e": 0
    },
    {
      "id": "image_12",
      "w": 500,
      "h": 500,
      "u": "",
      "p": Leaf_1_Beer_Animations_1,
      "e": 0
    }
  ],
  "layers": [
    {
      "ddd": 0,
      "ind": 1,
      "ty": 2,
      "nm": "Leaf 2",
      "refId": "image_0",
      "sr": 1,
      "ks": {
        "o": { "a": 0, "k": 100, "ix": 11 },
        "r": {
          "a": 1,
          "k": [
            {
              "i": { "x": [0.667], "y": [1] },
              "o": { "x": [0.333], "y": [0] },
              "t": -12,
              "s": [0]
            },
            {
              "i": { "x": [0.667], "y": [1] },
              "o": { "x": [0.333], "y": [0] },
              "t": 12,
              "s": [7]
            },
            { "t": 36, "s": [0] }
          ],
          "ix": 10,
          "x": "var $bm_rt;\n$bm_rt = loopOut('cycle', 0);"
        },
        "p": { "a": 0, "k": [153, 132, 0], "ix": 2 },
        "a": { "a": 0, "k": [153, 132, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 }
      },
      "ao": 0,
      "ip": 0,
      "op": 240,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 2,
      "ty": 2,
      "nm": "Hop 4 copy",
      "refId": "image_1",
      "sr": 1,
      "ks": {
        "o": { "a": 0, "k": 100, "ix": 11 },
        "r": {
          "a": 1,
          "k": [
            {
              "i": { "x": [0.667], "y": [1] },
              "o": { "x": [0.333], "y": [0] },
              "t": -12,
              "s": [0]
            },
            {
              "i": { "x": [0.667], "y": [1] },
              "o": { "x": [0.333], "y": [0] },
              "t": 12,
              "s": [-7]
            },
            { "t": 36, "s": [0] }
          ],
          "ix": 10,
          "x": "var $bm_rt;\n$bm_rt = loopOut('cycle', 0);"
        },
        "p": { "a": 0, "k": [377, 148, 0], "ix": 2 },
        "a": { "a": 0, "k": [377, 148, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 }
      },
      "ao": 0,
      "ip": 0,
      "op": 240,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 3,
      "ty": 2,
      "nm": "Hop 3",
      "refId": "image_2",
      "sr": 1,
      "ks": {
        "o": { "a": 0, "k": 100, "ix": 11 },
        "r": {
          "a": 1,
          "k": [
            {
              "i": { "x": [0.667], "y": [1] },
              "o": { "x": [0.333], "y": [0] },
              "t": 0,
              "s": [0]
            },
            {
              "i": { "x": [0.667], "y": [1] },
              "o": { "x": [0.333], "y": [0] },
              "t": 24,
              "s": [-7]
            },
            { "t": 48, "s": [0] }
          ],
          "ix": 10,
          "x": "var $bm_rt;\n$bm_rt = loopOut('cycle', 0);"
        },
        "p": { "a": 0, "k": [159, 356, 0], "ix": 2 },
        "a": { "a": 0, "k": [159, 356, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 }
      },
      "ao": 0,
      "ip": 0,
      "op": 240,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 4,
      "ty": 2,
      "nm": "Hop1",
      "refId": "image_3",
      "sr": 1,
      "ks": {
        "o": { "a": 0, "k": 100, "ix": 11 },
        "r": {
          "a": 1,
          "k": [
            {
              "i": { "x": [0.667], "y": [1] },
              "o": { "x": [0.333], "y": [0] },
              "t": -12,
              "s": [0]
            },
            {
              "i": { "x": [0.667], "y": [1] },
              "o": { "x": [0.333], "y": [0] },
              "t": 12,
              "s": [7]
            },
            { "t": 36, "s": [0] }
          ],
          "ix": 10,
          "x": "var $bm_rt;\n$bm_rt = loopOut('cycle', 0);"
        },
        "p": { "a": 0, "k": [180, 134, 0], "ix": 2 },
        "a": { "a": 0, "k": [180, 134, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 }
      },
      "ao": 0,
      "ip": 0,
      "op": 240,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 5,
      "ty": 2,
      "nm": "Leaf 3",
      "refId": "image_4",
      "sr": 1,
      "ks": {
        "o": { "a": 0, "k": 100, "ix": 11 },
        "r": {
          "a": 1,
          "k": [
            {
              "i": { "x": [0.667], "y": [1] },
              "o": { "x": [0.333], "y": [0] },
              "t": -8,
              "s": [0]
            },
            {
              "i": { "x": [0.667], "y": [1] },
              "o": { "x": [0.333], "y": [0] },
              "t": 16,
              "s": [-7]
            },
            { "t": 40, "s": [0] }
          ],
          "ix": 10,
          "x": "var $bm_rt;\n$bm_rt = loopOut('cycle', 0);"
        },
        "p": { "a": 0, "k": [141, 377, 0], "ix": 2 },
        "a": { "a": 0, "k": [141, 377, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 }
      },
      "ao": 0,
      "ip": 0,
      "op": 240,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 6,
      "ty": 2,
      "nm": "Hop 2",
      "refId": "image_5",
      "sr": 1,
      "ks": {
        "o": { "a": 0, "k": 100, "ix": 11 },
        "r": {
          "a": 1,
          "k": [
            {
              "i": { "x": [0.667], "y": [1] },
              "o": { "x": [0.333], "y": [0] },
              "t": 0,
              "s": [0]
            },
            {
              "i": { "x": [0.667], "y": [1] },
              "o": { "x": [0.333], "y": [0] },
              "t": 24,
              "s": [-7]
            },
            { "t": 48, "s": [0] }
          ],
          "ix": 10,
          "x": "var $bm_rt;\n$bm_rt = loopOut('cycle', 0);"
        },
        "p": { "a": 0, "k": [236, 243, 0], "ix": 2 },
        "a": { "a": 0, "k": [236, 243, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 }
      },
      "ao": 0,
      "ip": 0,
      "op": 240,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 7,
      "ty": 2,
      "nm": "Hop 5 copy",
      "refId": "image_6",
      "sr": 1,
      "ks": {
        "o": { "a": 0, "k": 100, "ix": 11 },
        "r": {
          "a": 1,
          "k": [
            {
              "i": { "x": [0.667], "y": [1] },
              "o": { "x": [0.333], "y": [0] },
              "t": -5,
              "s": [0]
            },
            {
              "i": { "x": [0.667], "y": [1] },
              "o": { "x": [0.333], "y": [0] },
              "t": 19,
              "s": [7]
            },
            { "t": 43, "s": [0] }
          ],
          "ix": 10,
          "x": "var $bm_rt;\n$bm_rt = loopOut('cycle', 0);"
        },
        "p": { "a": 0, "k": [321, 320, 0], "ix": 2 },
        "a": { "a": 0, "k": [321, 320, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 }
      },
      "ao": 0,
      "ip": 0,
      "op": 240,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 8,
      "ty": 2,
      "nm": "beer",
      "refId": "image_7",
      "sr": 1,
      "ks": {
        "o": { "a": 0, "k": 100, "ix": 11 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "p": { "a": 0, "k": [250, 250, 0], "ix": 2 },
        "a": { "a": 0, "k": [250, 250, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 }
      },
      "ao": 0,
      "ip": 0,
      "op": 240,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 9,
      "ty": 2,
      "nm": "Hop 4",
      "refId": "image_8",
      "sr": 1,
      "ks": {
        "o": { "a": 0, "k": 100, "ix": 11 },
        "r": {
          "a": 1,
          "k": [
            {
              "i": { "x": [0.667], "y": [1] },
              "o": { "x": [0.333], "y": [0] },
              "t": 0,
              "s": [0]
            },
            {
              "i": { "x": [0.667], "y": [1] },
              "o": { "x": [0.333], "y": [0] },
              "t": 24,
              "s": [7]
            },
            { "t": 48, "s": [0] }
          ],
          "ix": 10,
          "x": "var $bm_rt;\n$bm_rt = loopOut('cycle', 0);"
        },
        "p": { "a": 0, "k": [396, 305, 0], "ix": 2 },
        "a": { "a": 0, "k": [396, 305, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 }
      },
      "ao": 0,
      "ip": 0,
      "op": 240,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 10,
      "ty": 2,
      "nm": "Leaf 5",
      "refId": "image_9",
      "sr": 1,
      "ks": {
        "o": { "a": 0, "k": 100, "ix": 11 },
        "r": {
          "a": 1,
          "k": [
            {
              "i": { "x": [0.667], "y": [1] },
              "o": { "x": [0.333], "y": [0] },
              "t": -18,
              "s": [0]
            },
            {
              "i": { "x": [0.667], "y": [1] },
              "o": { "x": [0.333], "y": [0] },
              "t": 6,
              "s": [-7]
            },
            { "t": 30, "s": [0] }
          ],
          "ix": 10,
          "x": "var $bm_rt;\n$bm_rt = loopOut('cycle', 0);"
        },
        "p": { "a": 0, "k": [367, 414, 0], "ix": 2 },
        "a": { "a": 0, "k": [367, 414, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 }
      },
      "ao": 0,
      "ip": 0,
      "op": 240,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 11,
      "ty": 2,
      "nm": "Hop 5",
      "refId": "image_10",
      "sr": 1,
      "ks": {
        "o": { "a": 0, "k": 100, "ix": 11 },
        "r": {
          "a": 1,
          "k": [
            {
              "i": { "x": [0.667], "y": [1] },
              "o": { "x": [0.333], "y": [0] },
              "t": 0,
              "s": [0]
            },
            {
              "i": { "x": [0.667], "y": [1] },
              "o": { "x": [0.333], "y": [0] },
              "t": 24,
              "s": [-7]
            },
            { "t": 48, "s": [0] }
          ],
          "ix": 10,
          "x": "var $bm_rt;\n$bm_rt = loopOut('cycle', 0);"
        },
        "p": { "a": 0, "k": [160, 174, 0], "ix": 2 },
        "a": { "a": 0, "k": [160, 174, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 }
      },
      "ao": 0,
      "ip": 0,
      "op": 240,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 12,
      "ty": 2,
      "nm": "Leaf 4",
      "refId": "image_11",
      "sr": 1,
      "ks": {
        "o": { "a": 0, "k": 100, "ix": 11 },
        "r": {
          "a": 1,
          "k": [
            {
              "i": { "x": [0.667], "y": [1] },
              "o": { "x": [0.333], "y": [0] },
              "t": -14,
              "s": [0]
            },
            {
              "i": { "x": [0.667], "y": [1] },
              "o": { "x": [0.333], "y": [0] },
              "t": 10,
              "s": [-7]
            },
            { "t": 34, "s": [0] }
          ],
          "ix": 10,
          "x": "var $bm_rt;\n$bm_rt = loopOut('cycle', 0);"
        },
        "p": { "a": 0, "k": [97, 310, 0], "ix": 2 },
        "a": { "a": 0, "k": [97, 310, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 }
      },
      "ao": 0,
      "ip": 0,
      "op": 240,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 13,
      "ty": 2,
      "nm": "Leaf 1",
      "refId": "image_12",
      "sr": 1,
      "ks": {
        "o": { "a": 0, "k": 100, "ix": 11 },
        "r": {
          "a": 1,
          "k": [
            {
              "i": { "x": [0.667], "y": [1] },
              "o": { "x": [0.333], "y": [0] },
              "t": -6,
              "s": [0]
            },
            {
              "i": { "x": [0.667], "y": [1] },
              "o": { "x": [0.333], "y": [0] },
              "t": 18,
              "s": [7]
            },
            { "t": 42, "s": [0] }
          ],
          "ix": 10,
          "x": "var $bm_rt;\n$bm_rt = loopOut('cycle', 0);"
        },
        "p": { "a": 0, "k": [370, 157, 0], "ix": 2 },
        "a": { "a": 0, "k": [370, 157, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 }
      },
      "ao": 0,
      "ip": 0,
      "op": 240,
      "st": 0,
      "bm": 0
    }
  ],
  "markers": []
}
