import * as React from "react";
import { useState } from "react";
// @ts-ignore
import DistributorItem from "./components/distributor/Distributor";
import Modal from "./components/modal/Modal";
import PurchaseForm from "./components/PurchaseForm";
import {
  distributorItemsFirstRow,
  distributorItemsSecondRow,
} from "./Distributor.items";
// @ts-ignore
import classes from "./Purchases.module.css";
import BeerLottie from "./components/Lottie/BeerLottie";

export const Purchases = () => {
  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState({ country: "", hops: undefined });

  const handleClick = (props: any) => {
    if (props) {
      setModalData({
        country: props.country,
        hops: props.hops,
      });
    }
    setOpen(!open);
  };

  return (
    <div>
      <Modal show={open} close={handleClick} data={modalData} />
      <div className={classes.sectionWrapper}>
        <p className={classes.purchaseHeader}>Hops for Sale</p>
        <section className={classes.hopsSectionContainer}>
          <div className={classes.hopsForSale}>
            <BeerLottie />
            <p className={classes.purchaseDescription}>
              Amarillo® and other high quality aroma hops grown by Virgil
              Gamache Farms (VGF) are for sale domestically and internationally
              through our strong distribution network and direct sales. Request
              a free sample by filling out the hops sample request below.
              Distributors interested in more information about Amarillo® brand
              hops or other varieties of hops for sale from VGF, please contact
              our{" "}
              <a
                className={classes.clientManager}
                href="https://vgfinc.com/hops-for-sale/#request-sample-form"
              >
                Client Services Manager.
              </a>
            </p>
          </div>
        </section>
        <p className={classes.distributorSectionHeaders}>
          Click to view Distributors
        </p>
        <div className={classes.countriesContainer}>
          <div className={classes.distributorContainerFirstRow}>
            {distributorItemsFirstRow.map((distributor, index) => {
              return (
                <DistributorItem
                  key={index}
                  country={distributor.country}
                  hops={distributor.hops}
                  backgroundImage={distributor.backgroundImage}
                  hopBug={distributor.hopBug}
                  handleModal={handleClick}
                />
              );
            })}
            ;
          </div>
          <div className={classes.distributorContainerSecondRow}>
            {distributorItemsSecondRow.map((distributor, index) => {
              return (
                <DistributorItem
                  key={index}
                  country={distributor.country}
                  hops={distributor.hops}
                  backgroundImage={distributor.backgroundImage}
                  hopBug={distributor.hopBug}
                  handleModal={handleClick}
                />
              );
            })}
            ;
          </div>
        </div>
        {/* <section className={classes.sectionsContainer}>
          <p className={classes.distributorSectionHeaders}>
            Amarillo® Brand Samples
          </p>
          <p className={classes.requestText}>
            Use the request form below to receive a free sample of Amarillo®
            brand hops.
          </p>
          <PurchaseForm />
        </section> */}
      </div>
    </div>
  );
};
