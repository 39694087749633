import React from "react";
import Lottie from "react-lottie";
// @ts-ignore
import animationData from "./beer.js";
// @ts-ignore
import classes from "./BeerLottie.module.css";

const BeerLottie = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData
    };

    return (
        <div className={classes.root}>
            <Lottie options={defaultOptions} isStopped={false} isPaused={false} isClickToPauseDisabled={true} />
        </div>
    );
};

export default BeerLottie;
