// @ts-ignore
import AmericaBackground from "../../images/usa.png";

// @ts-ignore
import AustraliaBackground from "../../images/aus.png";

// @ts-ignore
import CanadaBackground from "../../images/can.png";

// @ts-ignore
import EUBackground from "../../images/eu.png";

// @ts-ignore
import JapanBackground from "../../images/jap.png";

// @ts-ignore
import HopImage from "../../images/Hop.png";

// @ts-ignore
import SABackground from "../../images/SouthAmerican.png";

export interface IHop {
  alt: string;
  filename: string;
  className?: string;
  to: string;
}

export interface IDistributor {
  country: string;
  backgroundImage: string;
  hopBug: string;
  hops: IHop[];
}

export const distributorItemsFirstRow: IDistributor[] = [
  {
    backgroundImage: AmericaBackground,
    country: "USA",
    hopBug: HopImage,
    hops: [
      {
        alt: "BSG CraftBrewing",
        filename: "BSGCircle.png",
        to: "https://bsgcraftbrewing.com/hops-american-hops-amarillo",
      },
      {
        alt: "Charles Faram",
        filename: "CharlesFaram.png",
        to: "https://charlesfaram.co.uk/",
      },
      {
        alt: "CROSBY HOP FARM",
        filename: "CrosbyCircle.png",
        to: "https://crosbyhops.com/",
      },
      {
        alt: "BATH-HAAS GROUP",
        filename: "BathhaasCircle.png",
        to: "https://www.barthhaas.com/en/hop-varieties/amarillo-vgxp01-c-v",
      },
      {
        alt: "YHQ Yakima Quality Hops",
        filename: "YQHCircle.png",
        to:
          "https://www.yakhops.com/all-products/centennial-hop-pellets-22-lb-h7e66-9pcc8-c2dk8-s4jmf-gra89",
      },
      {
        alt: "YAKIMA CHIEF HOPS",
        filename: "YakimaChiefHopsCircle.png",
        to: "https://shop.yakimachief.com/amarillo.html",
      },
      {
        alt: "HOPS DIRECT",
        filename: "HopsDirectCircle.png",
        to:
          "https://hopsdirect.com/collections/pellet-hops/products/amarillo-2019-pellets?variant=31786921721993",
      },
      {
        alt: "NY HOP GUILD",
        filename: "NYHGCircle.png",
        to:
          "https://www.nyhopguild.com",
      },
      {
        alt: "HOLLINGBERY",
        filename: "Hollingbery.png",
        to:
          "https://hollingberyandson.com/",
      },
      {
        alt: "MILL95",
        filename: "Mill95.png",
        to:
          "https://mill95hops.com/",
      },
    ],
  },
  {
    country: "EU",
    backgroundImage: EUBackground,
    hopBug: HopImage,
    hops: [
      {
        alt: "YAKIMA CHIEF HOPS",
        filename: "YakimaChiefHopsCircle.png",
        to: "https://shop.yakimachief.com/amarillo.html",
      },
      {
        alt: "CROSBY HOP FARM",
        filename: "CrosbyCircle.png",
        to: "https://crosbyhops.com/",
      },
      {
        alt: "BATH-HAAS GROUP",
        filename: "BathhaasCircle.png",
        to: "https://www.barthhaas.com/en/hop-varieties/amarillo-vgxp01-c-v",
      },
      {
        alt: "HOPSTORE",
        filename: "HopStoreCircle.png",
        to: "https://www.hopstore.fr/en/",
      },
      {
        alt: "JM HOPS",
        filename: "JMCircle.png",
        to: "https://jmhopscz.com/en/",
      },
      {
        alt: "CHARLES FARAM",
        filename: "CharlesFaram.png",
        to: "https://charlesfaram.co.uk/",
      },
      {
        alt: "HVG",
        filename: "HVGCircle.png",
        to: "https://hvg-germany.de/en/",
      },
    ],
  },
  {
    country: "AUSTRALIA",
    backgroundImage: AustraliaBackground,
    hopBug: HopImage,
    hops: [
      {
        alt: "YAKIMA CHIEF HOPS",
        filename: "YakimaChiefHopsCircle.png",
        to: "https://shop.yakimachief.com/amarillo.html",
      },
      {
        alt: "BATH-HAAS GROUP",
        filename: "BathhaasCircle.png",
        to: "https://www.barthhaas.com/en/hop-varieties/amarillo-vgxp01-c-v",
      },
      {
        alt: "BINTANI AUSTRALIA",
        filename: "BitaniCircle.png",
        to:
          "https://www.bintani.com.au/HUAMAT5/US-Amarillo%C2%AEVGXP01c.v.-%28Tropical-Expression%29-T90-x-5Kg/pd.php",
      },
    ],
  },
];

export const distributorItemsSecondRow: IDistributor[] = [
  {
    country: "CANADA",
    backgroundImage: CanadaBackground,
    hopBug: HopImage,
    hops: [
      {
        alt: "Brew CULTURE",
        filename: "BrewCultureCircle.png",
        to: "https://brewculture.com/product/151",
      },
      {
        alt: "CHARLES FARAM",
        filename: "CharlesFaram.png",
        to: "https://charlesfaram.co.uk/",
      },
      {
        alt: "HOPS CONNECT",
        filename: "HopsConnectCircle.png",
        to: "https://hopsconnect.com/",
      },
      {
        alt: "YAKIMA CHIEF HOPS",
        filename: "YakimaChiefHopsCircle.png",
        to: "https://shop.yakimachief.com/amarillo.html",
      },
    ],
  },
  {
    country: "ASIAN PACIFIC",
    backgroundImage: JapanBackground,
    hopBug: HopImage,
    hops: [
      {
        alt: "BARTH-HAAS GROUP",
        filename: "BathhaasCircle.png",
        to: "https://www.barthhaas.com/en/hop-varieties/amarillo-vgxp01-c-v",
      },
      {
        alt: "BREW NATION",
        filename: "BrewNation.png",
        to: "www.brewnation.in",
      },
      {
        alt: "GOLD MALT",
        filename: "GoldMalt.png",
        to: "https://goldmalt.com.vn/",
      },
      {
        alt: "KATAOKA",
        filename: "Katoaoka.png",
        to: "https://www.kataoka.com/en/",
      },
      {
        alt: "McKinnon International Inc.",
        filename: "MckinnonCircle.png",
        to: "http://mckinnoninternational.com/japanese/index.htm",
      },
      {
        alt: "YAKIMA CHIEF HOPS",
        filename: "YakimaChiefHopsCircle.png",
        to: "https://shop.yakimachief.com/amarillo.html",
      },
    ],
  },
  {
    country: "SOUTH AMERICA",
    backgroundImage: SABackground,
    hopBug: HopImage,
    hops: [
      {
        alt: "PEKKO",
        filename: "PekkoCircle.png",
        to: "https://www.pekko.com.ar/en/",
      },
      {
        alt: "HOPS COMPANY",
        filename: "HopsCompanyCircle.png",
        to: "https://hopscompany.com/",
      },
    ],
  },
];
