import "../css/styles.css";

import * as React from "react";

import { LayoutProps, withLayout } from "../components/Layout";

import { Purchases } from "../components/Purchases/Purchases";
import { SEOPagesDescription } from "../utils/seo.util";
import Seo from "../components/SEO/Seo";

const PurchasePage = (props: LayoutProps) => {
  return (
    <React.Fragment>
      <Seo {...SEOPagesDescription["hops-for-sale"]} />
      <Purchases />
    </React.Fragment>
  );
};

export default withLayout(PurchasePage);
