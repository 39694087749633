import * as React from "react";
import { IHop } from "../../Distributor.items";
// @ts-ignore
import classes from "./Modal.module.css";
import GenericImage from "../../../shared/image/Image";

const ImageContainer = (props: any) => {
  const { alt, filename, to } = props;
  return (
    <div className={classes.imageLink}>
      <a href={to} target="_blank" rel="noopener noreferrer">
        <GenericImage alt={alt} filename={filename} classes={classes.image} />
      </a>
    </div>
  );
};

const Modal = (props: any) => {
  const { show, close, data } = props;
  if (!show) {
    return <></>;
  }
  const { country, hops } = data;

  return (
    <div className={classes.backdrop} onClick={() => close()}>
      <div className={classes.modal}>
        <h2 className={classes.modalHeader}>
          {country} Hop and Brewing
          <br />
          Supply Distributors
        </h2>
        <div className={classes.modalContent}>
          <div className={classes.imageContainer}>
            {hops.map((item: IHop, index: number) => {
              return <ImageContainer key={index} {...item} />;
            })}
          </div>
        </div>
        <div className={classes.modalFooter}>
          <button className={classes.sendButton} onClick={() => close()}>
            back
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
